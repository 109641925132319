








































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import NavbarMenu from '@/components/NavbarMenu.vue'
import GetGasAvailable from '@/components/getGas/GetGasAvailable.vue'
import {EnvHelper} from '@/helpers/EnvHelper'
import {ShrikeHelper} from '@/helpers/ShrikeHelper'
import {GleederHelper} from '@/helpers/GleederHelper'
import BigNumber from 'bignumber.js'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import GetGasSwapButtonTooltipContent from '@/components/getGas/GetGasSwapButtonTooltipContent.vue'
import GetGasModal from '@/components/getGas/GetGasModal.vue'

@Component({
  components: {
    GetGasModal,
    GetGasSwapButtonTooltipContent,
    TooltipMobileContent,
    Tooltip,
    GetGasAvailable,
    NavbarMenu,
    AppFooter,
  },
})
export default class GetGasView extends MixinScreenSize {
  alreadyImputed = false
  isValid: boolean | null = null

  neoOwned: string | null = null

  neoToUse: string | null = null
  gasToReceive: string = '0.00'

  gasOutPerNeo: number | null = null

  isMobileTooltipVisible = false

  get temporarilyUnavailable() {
    return true
  }

  get hasWalletConnected() {
    return !!$.walletAdapter.n3Address
  }

  get hasInsufficientNeo() {
    return Number(this.neoOwned) < Number(this.neoToUse)
  }

  get inputIsRequired() {
    return Number(this.neoToUse) <= 0
  }

  get isProceedDisabled() {
    return (
      !this.temporarilyUnavailable ||
      !this.hasWalletConnected ||
      this.hasInsufficientNeo ||
      this.inputIsRequired
    )
  }

  async mounted() {
    const promises: Array<Promise<any>> = [
      this.populateAccountBalance(),
      this.calculateGasOut(),
    ]

    await $.await.run('populateAccountBalance', () => Promise.all(promises))
  }

  async calculateGasOut() {
    const response = await GleederHelper.getAmountsOutFlamingo({
      amountIn: '1',
      decimalsTokenToUse: EnvHelper.VUE_APP_BNEO_DECIMALS,
      hashTokenToUse: EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
      hashTokenToReceive: GleederHelper.gasScriptHash,
    })

    this.gasOutPerNeo = Number(
      (response.stack[0].value as {type: string; value: string}[])[1].value
    )
  }

  async handleProceedClick() {
    if (!this.neoToUse || !this.gasToReceive) {
      return
    }

    $.modal.open('getGasModal', {
      amountToUse: this.neoToUse,
      amountToReceive: this.gasToReceive,
    })
  }

  async calculateGasToReceive() {
    if (!this.neoToUse || !this.gasOutPerNeo || Number(this.neoToUse) === 0) {
      this.gasToReceive = '0.00'
      return
    }

    this.gasToReceive = BigNumber(this.gasOutPerNeo)
      .multipliedBy(BigNumber(this.neoToUse))
      // Decimals fixed back
      .shiftedBy(-EnvHelper.VUE_APP_BNEO_DECIMALS)
      // Business rule: we'll display for the user is always 0.5 less the value we received from RPC
      .minus(0.5)
      .toString()
  }

  handleMobileOpen() {
    this.isMobileTooltipVisible = !this.isMobileTooltipVisible
  }

  handleChangeNeoInputValue(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(
      /[^0-9]/g,
      ''
    )

    const parsedValue = parseInt(value)

    let newValue = value

    if (
      value.length - EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY.length > 1 &&
      parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)
    ) {
      newValue = EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY
    } else if (parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)) {
      newValue = value.slice(0, -1)
    }

    this.neoToUse = newValue

    this.alreadyImputed = true
    this.$emit('input', newValue)

    this.isValid = Number(this.neoToUse) <= Number(this.neoOwned)

    this.calculateGasToReceive()
  }

  @Watch('$store.state.walletAdapter.n3Address')
  onN3AddressChange() {
    this.populateAccountBalance()
  }

  private async populateAccountBalance() {
    try {
      const address = $.walletAdapter.n3Address

      if (!address) {
        this.neoOwned = 'N/A'
      } else {
        const accountBalance = await ShrikeHelper.getBalance(address)

        const neoBalance = accountBalance.find(
          item => item.token.hash === EnvHelper.VUE_APP_NEO_SCRIPT_HASH
        )

        if (neoBalance) {
          this.neoOwned = String(neoBalance.amount)
          return
        }

        this.neoOwned = '0'
      }
    } catch (error) {
      this.threatError(this.$translate('view.GetGas.unknownError'))
    }
  }

  private threatError(message: string) {
    $.toast.abort(message)
  }
}
