





















import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class GetGasAvailable extends Vue {
  @Prop({type: String, default: null}) neoOwned!: string | null
  @Prop({type: Boolean, default: null}) isValid!: boolean | null
}
