





































import {Vue, Component, Prop} from 'vue-property-decorator'
import {$} from '@/facade'

@Component({
  computed: {
    $() {
      return $
    },
  },
})
export default class GetGasSwapButtonTooltipContent extends Vue {
  @Prop({type: Boolean, required: false, default: false})
  hasWalletConnected!: boolean
  @Prop({type: Boolean, required: false, default: false})
  hasInsufficientNeo!: boolean
  @Prop({type: Boolean, required: false, default: false})
  temporarilyUnavailable!: boolean

  async connectWallet() {
    if (!$.walletAdapter.n3Address) {
      $.modal.open('connectWalletModal')
    }
  }
}
