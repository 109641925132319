





























































































import {Vue, Component} from 'vue-property-decorator'
import SwapLoadingTransactionStep from '@/components/swapToken/SwapLoadingTransactionStep.vue'
import SwapSwappedStep from '@/components/swapToken/SwapSwappedStep.vue'
import SwapUnexpectedErrorStep from '@/components/swapToken/SwapUnexpectedErrorStep.vue'
import {GetGasStep} from '@/enums/GetGasStep'
import {$} from '@/facade'
import {NeoHelper} from '@/helpers/NeoHelper'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'
import {BigNumber} from 'bignumber.js'
import {EnvHelper} from '@/helpers/EnvHelper'

@Component({
  components: {
    SwapLoadingTransactionStep,
    SwapSwappedStep,
    SwapUnexpectedErrorStep,
  },
  computed: {
    GetGasStep() {
      return GetGasStep
    },
  },
})
export default class GetGasModal extends Vue {
  currentStep: GetGasStep = GetGasStep.WAITING_SIGNATURE

  transactionHash: string | null = null

  get canBeClosed() {
    return (
      this.currentStep !== GetGasStep.WAITING_SIGNATURE &&
      this.currentStep !== GetGasStep.VALIDATING_TRANSACTION
    )
  }

  async openEvent({
    amountToUse,
    amountToReceive,
  }: {
    amountToUse: string
    amountToReceive: string
  }) {
    try {
      const formattedAmountToReceive = BigNumber(amountToReceive)
        .shiftedBy(EnvHelper.VUE_APP_BNEO_DECIMALS)
        .toString()

      const invokeParams: InvokeParams = {
        method: 'swapNeoUsingNeoProxyInvocation',
        params: {
          address: $.walletAdapter.n3Address!,
          gasAmountOutMin: formattedAmountToReceive,
          neoAmountIn: amountToUse,
        },
      }

      this.transactionHash = await $.walletAdapter.invokeN3Wallet(invokeParams)

      this.currentStep = GetGasStep.VALIDATING_TRANSACTION
      const isTransactionValid = await NeoHelper.validateTransaction(
        this.transactionHash!
      )

      if (!isTransactionValid) throw new Error()

      this.currentStep = GetGasStep.SWAPPED
    } catch (error) {
      this.currentStep = GetGasStep.UNKNOWN_ERROR
    }
  }

  closeEvent() {
    this.transactionHash = null
    this.currentStep = GetGasStep.WAITING_SIGNATURE
  }
}
